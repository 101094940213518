/** @jsx jsx */
import React from 'react';
import Slides from './Slides';
import { BrowserRouter, Link } from 'react-router-dom';
import Routes from './Routes';
import Sunset from '../../images/Sunset1.jpeg'

import { css, jsx } from '@emotion/core'

export default class SlideContent extends React.Component {

    constructor(props) {
        super(props);
        // this.state = {slides : Slides};



    }


    render() {
        return (
            <div id='SlideContent' css={css`width: fit-content;
                  height:80%;
                  margin:auto; 
                  padding-right:20px;          
          `}>
                
         <div className="row">
         <div css={css`height: 120px;
                    width: 120px;
                    overflow: hidden;
                    float: left;
                    border: solid 3px;
                    background-image: url('${this.props.content.PersonImage}');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;`}>
                    </div>
                    <div css={css`height: inherit;
                    width: fit-content;
                    float: left;
                    overflow: hidden;
                    border: solid 5px #white;
                    margin-left: 10px;
                    margin-top: 10px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    background-color: white;    
                    text-color: black`}>
                    <section>
                    
                    <p>{this.props.content.Description}</p>
                    <p>{this.props.content.Name}</p>
               
                     </section> 
               

                    </div>

            </div>
         </div>

     
        )
    }
}