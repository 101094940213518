/** @jsx jsx */
import React, { useEffect, useRef } from 'react';
import { css, jsx } from '@emotion/core'

import River3 from '../../images/River3.jpeg';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)



class IRSRepresentation extends React.Component {


    //    const IRSRepresentationRef = useRef(null)

    //    useEffect(()=>{
    //     scrollToRef(IRSRepresentationRef)
    //    })

    render() {
        return (
            <div className="row" id="IRSRepresentationDiv" css={css`padding-top:10px`} >
                <div className="col-md-5" css={css`
         background-image: linear-gradient(110deg, #eadfdf 70%, white 90%);`}
                >
                    <span css={css`font-family: Arial, Helvetica, sans-serif;font-size: 30px;
            `}> IRS Representation</span>

                    <div className="p-3 my-3" css={css`text-align: justify; text-justify: inter-word;`}>
                        <span>
                            We offer services to assist you with IRS representation and help reduce your tax liens and levies relating to your business or personal returns. We are committed to finding a solution to resolve your tax issues and want you to know that there is always a option available. Client privacy is our utmost priority and we do not disseminate information you share with us to any third party unless authorized specifically by you.
                        </span>
                    </div>
                </div>
                <div className="col-sm-12 col-md-9 col-lg-6">
                    <img src={River3} className="img-responsive" css={css`width: 100%;
            object-fit: cover;
            height: 100%;
            filter: brightness(100%)`}></img>
                </div>
            </div>
        )
    };
}
export default IRSRepresentation;  