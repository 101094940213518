/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core'


class Services extends React.Component{

    render(){
        return(
                <div css={css`
                background-color: lightgrey;
                width: inherit;
                border: 15px solid green;
                padding: 50px;
                margin: auto;`}>
                <h1>
                    Services
                </h1>
            </div>
        )
    }
}

export  default Services;