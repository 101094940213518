/** @jsx jsx */
import React from 'react';
import { Nav, NavItem, Container } from 'react-bootstrap';
import { SocialIcon } from 'react-social-icons';
import socialIcon from 'react-social-icons/dist/social-icon';
import { css, jsx } from '@emotion/core'
function Footer() {
  return (
    <div css={css` 
    margin: auto;   
    background: linear-gradient(-45deg, #1b193c 70%, #837d8a 80%); 
    color: white;   
   
    width: 100%`}>
        {/* <SocialIcon className="socialIcon" url="http://google.com" network="facebook" style={{ height: 25, width: 25 }}/>
        <SocialIcon className="socialIcon" url="http://google.com" network="twitter" style={{ height: 25, width: 25 }}/>
        <SocialIcon className="socialIcon" url="http://google.com" network="instagram" style={{ height: 25, width: 25 }}/> */}

Copyright © 1999 - 2012 1040 Times Tax Services, INC. All Rights Reserved.       
             
     
    </div>
  );
}

export default Footer;