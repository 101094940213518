import React from 'react'
import ReactDOM from 'react-dom'
import House1 from '../../images/House1.jpeg'
import House2 from '../../images/Sunset1.jpeg'
import Mountain1 from '../../images/Mountain1.jpeg'
import LightHouse from '../../images/River1.jpeg'
import NewYork1 from '../../images/NewYork1.jpeg'
import NewYork2 from '../../images/NewYork2.jpeg'
import River1 from '../../images/River1.jpeg'
import River2 from '../../images/River2.jpeg'
import River3 from '../../images/River3.jpeg'
import Sunset1 from '../../images/Sunset1.jpeg'
import Sunset2 from '../../images/Sunset2.jpeg'


const Images = [
    {  backGroundImage: House1  },
    {  backGroundImage: House2  },
    {  backGroundImage: Mountain1  },
    {  backGroundImage: LightHouse  },
    {  backGroundImage: NewYork1  },
    {  backGroundImage: NewYork2  },
    {  backGroundImage: River1  },
    {  backGroundImage: River2  },
    {  backGroundImage: River3  },
    {  backGroundImage: Sunset1  },
    {  backGroundImage: Sunset2  }
   ];

export default Images;

