/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core'

import NewYork2 from '../../images/NewYork2.jpeg';



class HomeComponent extends React.Component {

    render() {
        return (

            <div className="row">
                <div className="col-md-5" css={css`
                 background-image: linear-gradient(110deg, #eadfdf 70%, white 90%);`}
                >
                    <span css={css`font-family: Arial, Helvetica, sans-serif;font-size: 30px;
                    `}> 1040 Times Tax Services</span>

                    <div className="p-3 my-3" css={css`text-align: justify; text-justify: inter-word;`}>
                        <span>
                            1040 Times Tax Services is a certified public accounting professional corporation owned and managed by Asma Ahmed who is a CPA registered in the state of new york. The firm is geared to providing personalized and professional accounting services to its clients. The interest of the client always comes first and the clients benefit from a plan that identifies their long-term goals and provides a strategy for meeting them. We offer a no obligation initial consultation to assess your unique situation and to discuss the ways in which we can assist you in meeting your personal and business financial goals.                         
                        </span>
                    </div>
                </div>
                <div className="col-sm-12 col-md-9 col-lg-6">
                    <img src={NewYork2} className="img-responsive" css={css`width: 100%;
                    object-fit: cover;
                    height: 100%;
                    filter: brightness(70%) ;
                    position: relative`}></img>
                </div>
            </div>
        )
    }
}

export default HomeComponent;