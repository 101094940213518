/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core'
import Slides from './Slides';
// import './Slide.css';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import SlideContent from './SlideContent';
import SlideTransition from './SlideTransition';
import Slide from '../../NewComponents/Slide';
import Arrow from './Arrrow';
import Dots from './Dots';
import Sunset from '../../images/Sunset1.jpeg'
import { withTheme } from 'styled-components';





class SliderBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      translate: 0,
      length: this.props.slides.length
    };
  }


  getWidth = () => window.innerWidth * 0.7
  
 



  goToPrevSlide = () => {
    let index = this.state.activeIndex;
    let length = this.state.length;
    let translate = 0

    if (index < 1) {
      index = length - 1;
    }
    else {
      index--;
      translate = index * this.getWidth()
    }

    this.setState({
      activeIndex: index,
      translate: translate
    });
  }

  goToNextSlide = () => {
    let index = this.state.activeIndex;
    let length = this.state.length;
    let translate = 0

    if (index === length - 1) {
      index = 0

    }
    else {
      index++;
      translate = index * this.getWidth()
    }

    this.setState({
      activeIndex: index,
      translate: translate
    });
  }

  componentDidMount() {

    // this.startAutoPlay();
  }

  startAutoPlay = () => {
    this.slideInterval = setInterval(() => {
      this.goToNextSlide();
    }, 4000);
  }

  stopAutoPlay = () => {
    clearInterval(this.slideInterval);
  }

  restartAutoPlay = () => {
    this.slideInterval = setInterval(() => {
      this.goToNextSlide();
    }, 10000);
  }

  componentWillUnmount() {
    this.stopAutoPlay();
  }

  render() {
    return (
      
      <div id='SliderParent' css={SliderParentCss}>
        <div id='SlideBar' css={SliderBarCss} onClick={this.stopAutoPlay}>

          <SlideTransition
            translate={this.state.translate}
            transition='2.0'
            width={this.getWidth()* (this.props.slides.length/2)}
          >

            {this.props.slides.map((slide, i) => (
              <SlideContent key={i} content={slide} />
              
            ))}            
          </SlideTransition>
          {/* <Arrow direction="left" handleClick={this.goToPrevSlide} />
          <Arrow direction="right" handleClick={this.goToNextSlide} /> */}
          {/* <Dots slides={this.props.slides} activeIndex={this.state.activeIndex} /> */}

         
        </div>
      </div>


    )
  }
}

const SliderBarCss = css`
position: relative;
height: inherit;
width: 90%;
margin: auto;
overflow: hidden;
padding-bottom: inherit
// background-size: cover;
// background-repeat: no-repeat;
// background-position: center;
// overflow: hidden;
// object-fit: contain;

// background-image: url('${Sunset}');
`

const SliderParentCss = css`
display: flex;`
export default SliderBar;


