
/** @jsx jsx */
import React, { useState, useEffect, useRef } from "react";
import logo from './logo.svg';
import './App.css';




// import Slider from './components/slideComponents/lider';
import SliderContainer from './components/slideComponents/SliderContainer';
import MainContent from './components/MainContent';
import { Link, BrowserRouter } from 'react-router-dom';
import { css, jsx } from '@emotion/core'
import PageHeader from './components/headerComponent/PageHeader';
import Footer from './components/Footer';
import MenuBarComponent from './components/headerComponent/MenuBarComponent';
import BodyComponent from './components/innerComponents/HomeComponent';
import HomeComponent from './components/innerComponents/HomeComponent';
import BusinessIncorporation from './components/innerComponents/BusinessIncorporation';
import IndidualServices from './components/innerComponents/IndividualServices';
import BookKeeping from './components/innerComponents/BookKeeping';
import IRSRepresentation from './components/innerComponents/IRSRepresentation';


const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)


const App= ()=> {
  const [gotoContact, setGotoContact] = useState(false);
  const contactRef = useRef(null);

  useEffect(() => {
    if (gotoContact && contactRef.current) {
      scrollToRef(contactRef);
      // contactRef.current.scrollIntoView();
      setGotoContact(false);
    }
  }, [gotoContact, contactRef.current]);


  return (
    <div className="App">
      <div className="container clearfix"> 
      <BrowserRouter>
       <PageHeader />
       <div className="row">
        <MenuBarComponent/>
         <MainContent  />
         <Footer/>
         {/* <div id='MainContent' className="col-md-9 p-3 my-3" css={css`position:relative; padding-top:3px`}>
          <HomeComponent/>
          <IRSRepresentation ref={contactRef}/>
          </div> */}
       </div>
       </BrowserRouter>
       </div>
      {/* <div css={rootContainerCss}>
        
        <MenuBarComponent/>
          <MainContent />
          <SliderContainer />
        </BrowserRouter>
      </div> */}
      {/* <Footer /> */}

    </div>
  );
}

const rootContainerCss = css` width: inherit; height: 100%;
margin: auto;
padding: 20px 20px 20px 20px;
background: black;`




export default App;
