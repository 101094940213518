/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core'

import NewYork1 from '../../images/NewYork1.jpeg';

class IndidualServices extends React.Component {
    render() {
        return (
            <div className="row" css={css`padding-top:10px; padding-bottom: 10px;`}>
                <div className="col-md-5 " id="IndividualServicesDiv" css={css`
         background-image: linear-gradient(110deg, #eadfdf 70%, white 90%);`}
                >
                    <span css={css`font-family: Arial, Helvetica, sans-serif;font-size: 30px;
            `}> INDIVIDUAL SERVICES</span>

                    <div className="p-3 my-3" css={css`text-align: justify; text-justify: inter-word;`} >
                        <span>
                            Our firm offers income tax preparation services and can electronically file your federal, state and local tax returns in all 50 states. We will work with you to ensure that all the deductions and credits that you are duly entitled to are accounted for on your tax return so you can keep more of your hard earned money and not pay more taxes than what you truly owe. We also determine and file estimated tax payments for self employed individuals so they can avoid any penalties imposed by federal and state authorities. If necessary we can negotiate with federal, state and local taxing authorities on your behalf and can duly represent you. We also offer guidance in retirement planning to our clients by analyzing their projected income and expenses and suggesting investment strategies to build up their retirement fund.
                        </span>
                    </div>
                </div>
                <div className="col-sm-12 col-md-9 col-lg-6">
                    <img src={NewYork1} className="img-responsive" css={css`width: 100%;
            object-fit: cover;
            height: 100%;
            filter: brightness(70%)`}></img>
                </div>
            </div>
        )
    }
}
export default IndidualServices;  