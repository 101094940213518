/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core'
import { Tabs, Tab, Container, Navbar, Nav, Form, FormControl, Button, NavDropdown } from 'react-bootstrap';

class BusinessIncorporation extends React.Component {

    showDiv = (divName) => {
        this.hideAllDiv();
        document.getElementById(divName).style.display = "block";
    }

    componentDidMount() {
        this.hideAllDiv();
        document.getElementById('IncorporationDiv').style.display = "block";
    }

    hideAllDiv = () => {
        document.getElementById('IncorporationDiv').style.display = "none";
        document.getElementById('ProprietorshipDiv').style.display = "none";
        document.getElementById('GeneralPartnershipDiv').style.display = "none";
        document.getElementById('LimitedPartnershipDiv').style.display = "none";
        document.getElementById('CCorporationDiv').style.display = "none";
        document.getElementById('SCorporationDiv').style.display = "none";
        document.getElementById('LimitedLiabilityDiv').style.display = "none";
        document.getElementById('NonProfitDiv').style.display = "none";

    }

    render() {
        return (
            <div className="row" >
                <div>
                    <div id="BusinessIncorporationDiv">
                        <Navbar style={{ backgroundColor: '#1b193c', color: 'black' }} expand="md" variant="dark" className="flex-column">
                            <Navbar.Brand className="navbar-brand">Business Incorporation</Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="row">
                                    <Nav.Link act css={this.itemBorder} onClick={() => this.showDiv('IncorporationDiv')}>Business Entity Structure and Incorporation</Nav.Link>
                                    <Nav.Link css={this.itemBorder} onClick={() => this.showDiv('ProprietorshipDiv')}>Sole Proprietorship</Nav.Link>
                                    <Nav.Link css={this.itemBorder} onClick={() => this.showDiv('GeneralPartnershipDiv')}>General Partnership</Nav.Link>
                                    <Nav.Link css={this.itemBorder} onClick={() => this.showDiv('LimitedPartnershipDiv')}>Limited Partnership</Nav.Link>
                                    <Nav.Link css={this.itemBorder} onClick={() => this.showDiv('CCorporationDiv')}>C Corporations</Nav.Link>
                                    <Nav.Link css={this.itemBorder} onClick={() => this.showDiv('SCorporationDiv')}>S Corporations</Nav.Link>
                                    <Nav.Link css={this.itemBorder} onClick={() => this.showDiv('LimitedLiabilityDiv')}>Limited Liability Corporation (Llc’s)</Nav.Link>
                                    <Nav.Link css={this.itemBorder} onClick={() => this.showDiv('NonProfitDiv')}>Non-Profit Corporation</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                    <div className="container clearfix">
                        <div className="row">
                            <div className="p-3 my-3" id="IncorporationDiv" css={css`
                                           filter: brightness(40%);
                                            background-size: cover;
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            overflow: hidden;
                                            object-fit: contain;
                                            color: white;
                                            position:relative; padding-top:3px; overflow:auto;`}>
                                <h3>BUSINESS ENTITY STRUCTURE AND INCORPORATION</h3>
                                <div className="p-3 my-3" css={css`text-align: justify; text-justify: inter-word;`}>
                                    <span>
                                        Incorporation refers to the act of creating a new corporation under the laws of a particular state. Corporations and LLc’s offer greater asset protection than a sole proprietorship or general partnership. A sole proprietor or general partner has unlimited personal liability for the debts and obligations of the business whereas corporations and llc’s allow owners to separate and protect their personal assets. Choosing the right structure for your business will maximize the chances of its financial and operational success and is a key decision for any new business setup. At 1040 times tax services, we can guide you as to which form of incorporation will offer you maximum tax benefit and legal protection of your business assets and can incorporate your business usually within 24 hours in all states. Click on other tabs to know more about business structures:
                                    </span>
                                </div>
                            </div>

                            <div className="p-3 my-3" id="ProprietorshipDiv" css={css`
                                           filter: brightness(40%);
                                            background-size: cover;
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            overflow: hidden;
                                            object-fit: contain;
                                            color: white;
                                            position:relative; padding-top:3px; overflow:auto;`}>
                                <h3>SOLE PROPRIETORSHIP</h3>
                                <div className="p-3 my-3" css={css`text-align: justify; text-justify: inter-word;`}>
                                    <span>
                                         The formation of a sole proprietorship usually requires filing an assumed name certificate at a local or state public office and also needs a business license to conduct business under state laws or local ordinances.
                                    </span>
                                </div>
                            </div>

                            <div className="p-3 my-3" id="GeneralPartnershipDiv" css={css`
                                           filter: brightness(40%);
                                            background-size: cover;
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            overflow: hidden;
                                            object-fit: contain;
                                            color: white;
                                            position:relative; padding-top:3px; overflow:auto;`}>
                                <h3>GENERAL PARTNERSHIP</h3>
                                <div className="p-3 my-3" css={css`text-align: justify; text-justify: inter-word;`}>
                                    <span>
                                        This business structure requires no local or state filing and its formed when 2 or more people intend to work together to conduct a business activity. The distinguishing feature of this business type is the unlimited liability of the partners.
                                    </span>
                                </div>
                            </div>

                            <div className="p-3 my-3" id="LimitedPartnershipDiv" css={css`
                                           filter: brightness(40%);
                                            background-size: cover;
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            overflow: hidden;
                                            object-fit: contain;
                                            color: white;
                                            position:relative; padding-top:3px; overflow:auto;`}>
                                <h3>LIMITED PARTNERSHIP</h3>
                                <div className="p-3 my-3" css={css`text-align: justify; text-justify: inter-word;`}>
                                    <span>
                                        A limited partnership is an incorporated business composed of one or more general partners and one or more limited partners. The general partners manage the business and share wholly in its profits and losses. Limited partners share in the profits of the business, but their risks are limited to the extent of their investment.
                                    </span>
                                </div>
                            </div>

                            <div className="p-3 my-3" id="CCorporationDiv" css={css`
                                           filter: brightness(40%);
                                            background-size: cover;
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            overflow: hidden;
                                            object-fit: contain;
                                            color: white;
                                            position:relative; padding-top:3px; overflow:auto;`}>
                                <h3>C CORPORATIONS</h3>
                                <div className="p-3 my-3" css={css`text-align: justify; text-justify: inter-word;`}>
                                    <span>
                                        A C Corporation is a separate legal tax paying entity. It is taxed at its own corporate tax rate when income is earned and when this income is distributed to its shareholders as wages or dividends it is taxed to them also at their individual income tax rate.
                                </span>
                                </div>
                            </div>

                            <div className="p-3 my-3" id="SCorporationDiv" css={css`
                                           filter: brightness(40%);
                                            background-size: cover;
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            overflow: hidden;
                                            object-fit: contain;
                                            color: white;
                                            position:relative; padding-top:3px; overflow:auto;`}>
                                <h3>S CORPORATIONS</h3>
                                <div className="p-3 my-3" css={css`text-align: justify; text-justify: inter-word;`}>
                                    <span>
                                        The distinguishing characteristic of this incorporated business is the flow through of corporate income and losses to the personal tax returns of its shareholders who are then taxed at their individual income tax rates.
                                    </span>
                                </div>
                            </div>


                            <div className="p-3 my-3" id="LimitedLiabilityDiv" css={css`
                                           filter: brightness(40%);
                                            background-size: cover;
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            overflow: hidden;
                                            object-fit: contain;
                                            color: white;
                                            position:relative; padding-top:3px; overflow:auto;`}>
                                <h3>LIMITED LIABILITY CORPORATION (LLC’S)</h3>
                                <div className="p-3 my-3" css={css`text-align: justify; text-justify: inter-word;`}>
                                    <span>
                                        A LIMITED LIABILITY COMPANY (LLC) is a business structure allowed by state statute. An LLC business entity must file a corporation, partnership or sole proprietorship tax return and is not is not responsible for taxes on its profits. Instead, the LLC's owners, known as "members," report their share of business profit and loss on their personal tax returns, similar to tax reporting for a General Partnership and S corporations . This is known as "pass-through" taxation.
                                    </span>
                                </div>
                            </div>

                            <div className="p-3 my-3" id="NonProfitDiv" css={css`
                                           filter: brightness(40%);
                                            background-size: cover;
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            overflow: hidden;
                                            object-fit: contain;
                                            color: white;
                                            position:relative; padding-top:3px; overflow:auto;`}>
                                <h3>NON PROFIT CORPORATIONS</h3>
                                <div className="p-3 my-3" css={css`text-align: justify; text-justify: inter-word;`}>
                                    <span>
                                        <p>A nonprofit corporation is a legal entity and is typically run to promote an idea or goal rather than in the interests of profit. Many nonprofits serve the public interest, but some engage in private sector activities also.</p>
                                        <p>There are many forms of incorporating your business and the correct form of incorporation is a very important decision which requires a careful analysis of the goals and objectives of the business owner and the nature of the business</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    itemBorder = () => css`border-right:1px solid #84B6D0;
                       border-left:1px solid #84B6D0;color:#fff;`
};
export default BusinessIncorporation;