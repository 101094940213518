/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core'
import Slides from '../slideComponents/Slides';
import Sunset from '../../images/Sunset1.jpeg'
import Images from './ImageHolder';
import MenuBarComponent from './MenuBarComponent';
import { SocialIcon } from 'react-social-icons';
import { MdEmail, MdLocalPhone } from "react-icons/md";
import {FaFax} from "react-icons/fa";



export default class PageHeader extends React.Component {


  render() {
    return (
      
        <div css={css`padding-top: 5px; padding-bottom: 5px; background: linear-gradient(-45deg, #1b193c 70%, #837d8a 80%); display:flex;
                position: -webkit-sticky;
                position: sticky;
                top: 0;`} classNames="row h-100 col-xs-2">
          {/* <div css={css`color: yellow; text-align: left; padding-left 20px;  font-size: medium; font-weight: bold`} >
            <span>Times Tax services</span>
          </div> */}
          <div css={css`margin: auto;`}>
            <SocialIcon className="socialIcon" url="https://www.facebook.com/1040TimesTaxServices/" network="facebook" style={{ height: 25, width: 25 }} />
            {/* <SocialIcon className="socialIcon" url="http://google.com" network="twitter" style={{ height: 25, width: 25 }} />
            <SocialIcon className="socialIcon" url="http://google.com" network="instagram" style={{ height: 25, width: 25 }} /> */}

            <MdEmail color="white" size="15px"></MdEmail><span css={css`color:white; font-size:12px;`}> info@1040times.net</span>
            <span css={css`padding-left: 5px`}></span>  
            <MdLocalPhone color="white" size="15px"></MdLocalPhone><span css={css`color:white; font-size:10px;`}> 917-470-4271, 347-423-7557</span>
            <span css={css`padding-left: 5px`}></span>  
            <FaFax color="white" size="15px"></FaFax><span css={css`color:white; font-size:10px;`}> 480-275-3745</span>
          </div>
        </div>      
    );
  }
}










