/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core'

import NewYork2 from '../../images/River2.jpeg';

class BookKeeping extends React.Component {

    render() {
        return (
            <div className="row"  css={css`padding-top:10px; padding-bottom: 10px;`}>
                <div className="col-md-5" id="BookkeepingDiv" css={css`
         background-image: linear-gradient(110deg, #eadfdf 70%, white 90%);`}
                >
                    <span css={css`font-family: Arial, Helvetica, sans-serif;font-size: 30px;
                         `}> Bookkeeping</span>

                    <div className="p-3 my-3" css={css`text-align: justify; text-justify: inter-word;`}>
                        <span>
                            <p>Accurate and timely record keeping is essential for any business and it provides information necessary for filing tax returns and making important business decisions which will increase business profitability. Regular financial reports may also be required by your banker, insurance agent and even your investors. Outsourcing bookkeeping especially for small businesses makes good business sense as it results in cost savings by freeing up office space and eliminating the need to purchase and upgrade software.</p>
                            <p>We at 1040 Times Tax Services understand that business owners need to focus their time and energy on growing their businesses rather than maintaining their own books and therefore provide services that will keep their accounting records updated. Our accurate bookkeeping enables us to prepare interim and yearly financial statements of your company and analyze its financial position enabling you in making informed decisions for your business.</p>
                        </span>
                    </div>
                </div>
                <div className="col-sm-12 col-md-9 col-lg-6">
                    <img src={NewYork2} className="img-responsive" css={css`width: 100%;
            object-fit: cover;
            height: 100%;
            filter: brightness(80%)`}></img>
                </div>
            </div>
        )
    }
}
export default BookKeeping;  