/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core'

import River1 from '../../images/River1.jpeg';

class BusinessServices extends React.Component {
    render() {
        return (
            <div className="row"  css={css`padding-top:10px; padding-bottom: 10px;}`} >
                <div className="col-md-5 " id="BusinessServicesDiv" css={css`
         background-image: linear-gradient(110deg, #eadfdf 70%, white 90%);`}
                >
                    <span css={css`font-family: Arial, Helvetica, sans-serif;font-size: 30px;
            `}> BUSINESS SERVICES</span>

                    <div className="p-3 my-3" css={css`text-align: justify; text-justify: inter-word;`}>
                        <span>
                            <p>1040 Times Tax Services offer most business accounting services enabling the business owner to concentrate on the operations and growth of the business. As an employer you have specific payroll responsibilities that are required by federal, state and local government agencies and reporting these payroll taxes to these agencies is of vital importance to your business. No matter how small or big an organization is, payroll is one of the most complex and critical issue every business has to deal with. Our firm offers complete services for payroll preparation and payroll tax reporting so you can be rest assured that these obligations have been met.</p>
                            <p>We also provide financial and business forecasting services including business projections and pro forma analysis of your potential financial position to help you anticipate and plan for unexpected contingencies.</p>
                        </span>
                    </div>
                </div>
                <div className="col-sm-12 col-md-9 col-lg-6">
                    <img src={River1} className="img-responsive" css={css`width: 100%;
            object-fit: cover;
            height: 100%;
            filter: brightness(80%)`}></img>
                </div>
            </div>
        )
    }
}
export default BusinessServices;  