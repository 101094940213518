/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/core'
// import SliderContent from './SliderContent'
// import Slide from './Slide'
import SliderBar from './SliderBar'
import Slides from './Slides'
import Sunset from '../../images/Sunset1.jpeg'
const SliderContainer = props =>{

  

      return(
          <div id='SliderContainer' css={SliderContainerCss}>
              <SliderBar slides={Slides}/>
              {/* <img src={Sunset} style={{ objectFit: "fill", width: "60%", height: "100%"}}/> */}
              
              
          </div>

      )

      


}
const SliderContainerCss = css`
      position: relative;
      height: 60%;
      width: 100%;
      margin: auto;
      overflow: hidden;
      padding-bottom: inherit;

      `

export default SliderContainer;