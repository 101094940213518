/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core'
import { Container, Navbar, Nav, Form, FormControl, Button, NavDropdown } from 'react-bootstrap';
import { MdHome } from 'react-icons/md';


const MenuBarComponent=({ onClickContact }) => {


  
  
  
        return (
            // <div css={css`margin: auto`}>
            <div className="col-md-3">
                <div css={css`min-height: 100vh; background: linear-gradient( #1b193c  60%, gray 90%);`} >
                    <Navbar style={{ backgroundColor: '#1b193c' }} expand="lg" variant="dark" className="flex-column">
                        <Navbar.Brand className="navbar-brand" href="#home">Times Tax Services</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="flex-column">
                                <Nav.Link href="/Home">
                                    <MdHome size="20px" color="#afadcc" />
                                </Nav.Link>
                                <Nav.Link href="/BusinessIncorporation">Business Incorporation</Nav.Link>
                                <NavDropdown title="Taxation" id="basic-nav-dropdown">
                                    <NavDropdown.Item className="nav-item" href="/IndividualServices">Individual Services</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item className="nav-item" href="/BusinessServices">Business Services</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/BookKeeping">Book Keeping</Nav.Link>
                                <Nav.Link href="/IRSrepresentation" onClick={() => onClickContact()}>IRS Representation</Nav.Link>
                                {/* <Nav.Link href="/AboutUs">About Us</Nav.Link> */}                                
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
        )
    }

export default MenuBarComponent;