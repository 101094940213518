/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/core'

class LeftArrow extends React.Component {
  render() {
    return(
     
    //   <a
    //   href="#"
    //   className="carousel__arrow carousel__arrow--left"
    //   onClick={e => this.goToPrevSlide(e)}
    // >
    //   <span className="fa fa-2x fa-angle-left" />
    // </a>

    <div css={css`
    border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;`}></div>
    )
  }
}

export default LeftArrow;