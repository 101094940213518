/** @jsx jsx */

import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core';
const move = keyframes`
from {
  transform: translate3d(-50%,0,0);
}

to{
  transform: translate3d(50%,0,0);
}`
const SlideTransition = styled.div`
  
  animation: ${move} 30s linear infinite alternate;
  height: 100%;
  width: ${props => props.width}px;
  display: flex;
 
`

export default SlideTransition;