import React from 'react'
import ReactDOM from 'react-dom'
import House1 from '../../images/House1.jpeg'
import House2 from '../../images/Sunset1.jpeg'
import LightHouse from '../../images/River1.jpeg'

const Slides = [
    {
        Name : 'Ashwin Raghavan',
        PersonImage: House1,
        Description: 'Ashwin Raghavan Testimonial',
       
    },
    {
        Name : 'Azfar Malik',
        PersonImage: House2,
        Description: 'Azfar Malik Testimonial',
       
    },
    {
        Name : 'Omar Malik',
        PersonImage: House1,
        Description: 'Omar Malik Testimonial',
       
    },
    {
        Name : 'Murali Aarani',
        PersonImage: House2,
        Description: 'Murali testimonial',
       
    },
    {
        Name : 'Vineed Unnikrishnan',
        PersonImage: House1,
        Description: 'Vineed testimonial',
       
    }
];

export default Slides;

