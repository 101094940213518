/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/core'
import { Route , BrowserRouter} from 'react-router-dom';

import Routes from './slideComponents/Routes';

class MainContent extends React.Component{

    render(){
        return(
            <div id='MainContent' className="col-md-9" css={css`position:relative; padding-top:3px`}>
              <Route component={Routes}></Route>  
              </div>        
        )
    }
}
export default MainContent;