import React, { Component } from 'react';
import { css, jsx } from '@emotion/core'

import { Route, Link, Switch, Redirect } from 'react-router-dom';
import AboutUs from '../innerComponents/AboutUs';
import Services from '../innerComponents/Services';

import Slides from './Slides';
import HomeComponent from '../innerComponents/HomeComponent';
import BusinessIncorporation from '../innerComponents/BusinessIncorporation';
import IRSRepresentation from '../innerComponents/IRSRepresentation';
import BookKeeping from '../innerComponents/BookKeeping';
import IndidualServices from '../innerComponents/IndividualServices';
import BusinessServices from '../innerComponents/BusinessServices';

// const slideBox = ({slide}) => (
//     <div 
//     css={css`
//         height: 100;
//         width: 100%;
//         background-image: url('${content.img}');
//         background-size: cover;
//         background-repeat: no-repeat;
//         background-position: center;
//     `}
//     />
// )

class Routes extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
                    
            <Switch>
                <Route exact path="/Home"  component={HomeComponent} />
                <Route exact path="/">
                    <Redirect to="/Home" />
                </Route>
                <Route exact path="/AboutUs" component={AboutUs} />
                <Route exact path="/IndividualServices" component={IndidualServices} />
                <Route exact path="/BusinessServices" component={BusinessServices}/>
                <Route exact path="/BusinessIncorporation" component={BusinessIncorporation} /> 
                <Route exact path="/IRSrepresentation" component={IRSRepresentation} /> 
                <Route exact path="/BookKeeping" component={BookKeeping} /> 
            </Switch>
            
        )
    }
}



export default Routes;